import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormControl, FormGroup } from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import { UserData } from "@api/account";
import { BillingDetails } from "@api/clients/models/billing-details.models";
import { ClientsService } from "@api/clients/services";
import { ClientBillingDialogComponent } from "@modules/clients/components/client-billing-dialog/client-billing-dialog.component";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  BehaviorSubject,
  catchError,
  debounceTime,
  map,
  Observable,
  of,
  shareReplay,
  startWith,
  switchMap,
  take,
} from "rxjs";
export interface CheckoutData {
  billingAddressId: number;
  contactId: number;
}
export type ToFormGroupType<T> = FormGroup<{
  [K in keyof T]: FormControl<T[K] | null>;
}>;

export type CheckoutDataForm = ToFormGroupType<CheckoutData>;
@Component({
  selector: "app-checkout-step",
  templateUrl: "./checkout-step.component.html",
  styleUrls: ["./checkout-step.component.scss"],
})
export class CheckoutStepComponent implements OnInit {
  @Input()
  clientId: number;

  @Input()
  checkoutForm: CheckoutDataForm;

  clientService = inject(ClientsService);
  changeDetectorRef = inject(ChangeDetectorRef);
  destroyRef = inject(DestroyRef);

  private addressesSubject = new BehaviorSubject<BillingDetails[]>([]);
  addresses$ = this.addressesSubject.asObservable();

  users$: Observable<UserData[]>;

  userSearchControl: FormControl = new FormControl("");
  selectedContact$ = new BehaviorSubject<UserData>(null);

  constructor(public dialog: MatDialog) {}

  ngOnInit() {
    this.clientService
      .listBillingAddress(this.clientId)
      .pipe(take(1))
      .subscribe((addresses) => this.addressesSubject.next(addresses));

    this.users$ = this.userSearchControl.valueChanges.pipe(
      startWith(""),
      debounceTime(300),
      switchMap((value) =>
        this.clientService
          .getUsers(this.clientId, {
            search_text: value,
            only_hr_users: true,
          })
          .pipe(catchError(() => of([])))
      ),
      map((users) => {
        if (this.selectedContact$.value) {
          const exists = !users.some(
            (user) => user.id === this.selectedContact$.value.id
          );
          if (exists) {
            return [this.selectedContact$.value, ...users];
          }
        }
        return users;
      }),
      shareReplay(1)
    );
  }
  get LoadingTypeEnum() {
    return LoadingTypeEnum;
  }

  selectionChanged(event: UserData) {
    this.selectedContact$.next(event);
    this.changeDetectorRef.markForCheck();
  }

  createBillingAddress() {
    this.dialog
      .open(ClientBillingDialogComponent, {
        data: {
          billingDetails: null,
          entityId: this.clientId,
        },
      })
      .afterClosed()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((resp) => {
        if (resp) {
          this.updateAddresses(resp);
        }
      });
  }

  private updateAddresses(newAddress: BillingDetails) {
    const currentAddresses = this.addressesSubject.getValue();
    this.addressesSubject.next([...currentAddresses, newAddress]);
  }
}
