<div class="top-wrapper items-start">
  <!-- search input -->
  <div class="advanced-search-input w-full flex p-0">
    <div class="flex filter-wrapper justify-end">
      <div *ngIf="showTextField" class="icon-inside-input">
        <input
          [formControl]="keywordFormControl"
          type="text"
          name="search_text"
          class="w-full"
          placeholder="{{ 'GENERALS.SEARCH' | translate }} {{
            searchPlaceholder | translate | lowercase
          }}"
        />
        <i class="icon-search-1"></i>
      </div>

      <div class="filter-btn-wrapper flex items-center my-3">
        <div
          (click)="toggleAdvanceSearch()"
          (keyup)="toggleAdvanceSearch()"
          class="toggle-search-btn"
          *ngIf="availableFilters && availableFilters.length > 0"
        >
          <mat-icon svgIcon="filter" class="button-icon"></mat-icon>

          <span>{{
            (!showAdvanced
              ? "GENERALS.ADVANCED-SEARCH"
              : "GENERALS.HIDE-ADVANCED-SEARCH"
            ) | translate
          }}</span>
        </div>

        <div *ngIf="total" class="text-secondary total-items-fiter">
          <span
            class="flex items-center"
            *ngIf="availableFilters && availableFilters.length > 0"
            >|</span
          >{{ total }} {{ "GENERALS.RESULTS" | translate | lowercase }}
        </div>

        <ng-container class="filter-btn-wrapper" *ngIf="previewFilters.length">
          <div class="flex items-center">|</div>
          <div class="flex items-center">
            <ng-container *ngFor="let item of previewFilters; let i = index">
              <span class="text-secondary total-items-fiter"
                >{{ item.key }}: {{ item.text
                }}{{ i + 1 !== previewFilters.length ? ";" : "" }}</span
              ></ng-container
            >
          </div>
        </ng-container>
      </div>
    </div>

    <div class="clear"></div>
  </div>
  <!-- end search input -->

  <div class="clear-wrapper">
    <!-- right -->
    <div class="float-right">
      <app-elements-on-page
        [perPage]="perPage"
        (getItem)="perPage = $event; setPerPage()"
      ></app-elements-on-page>
      <!-- end elem on page -->
    </div>
    <div
      class="toggle-search-btn flex !justify-end my-3"
      (click)="clearAllFilters()"
      (keyup)="clearAllFilters()"
    >
      {{ "LABEL.CLEAR-ALL-FILTERS" | translate }}
    </div>
    <!-- end right -->
  </div>
</div>

<!-- toggle advanced tools for search (filters)-->
<div
  #showAdvancedSearch
  class="toggle-advanced-search open-filters-wrapper"
  *ngIf="showAdvanced"
>
  <ng-container *ngFor="let filterElement of availableFilters">
    <div
      class="advance-search-item"
      *ngIf="filterOptions && filterOptions[filterElement.id]"
    >
      <div class="select-group-block">
        <label>{{ filterElement.label | translate }}</label>

        <ng-select
          class="customiz-select"
          [items]="filterElement.data"
          bindLabel="text"
          bindValue="id"
          placeholder="{{ 'FILTERS.SEARCH_BY_SELECT' | translate }}"
          [(ngModel)]="filters[filterElement.id]"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          (change)="setSelectFilter(filters)"
          (search)="filterSearch($event, filterElement)"
          (open)="openFilter(filterElement)"
          (clear)="clearFilter(filterElement)"
          (scrollToEnd)="loadMoreData(filterElement)"
          [loading]="filterElement.isLoading"
        >
        </ng-select>
      </div>
    </div>

    <div
      class="advance-search-item"
      *ngIf="multipleFilterOptions && multipleFilterOptions[filterElement.id]"
    >
      <div class="select-group-block">
        <label [class]="filterElement.label.indexOf('ID') > -1 ? 'ID' : ''">
          {{ filterElement.label | translate }}
        </label>

        <ng-select
          class="customiz-select multiple-select tag-select"
          [items]="filterElement.data"
          [searchable]="true"
          [clearable]="true"
          [multiple]="true"
          bindLabel="text"
          bindValue="id"
          placeholder="{{ filterElement.label | translate }}"
          notFoundText="{{ 'GENERALS.NO-ITEMS-FOUND' | translate }}"
          [(ngModel)]="filters[filterElement.id]"
          (change)="setMultipleSelectFilter(filterElement.id, $event)"
        >
          // improve the process
        </ng-select>
      </div>
    </div>

    <div
      class="incurred-date advance-search-item filter-select-date"
      *ngIf="dateFilterOptions && dateFilterOptions[filterElement.id]"
    >
      <div class="select-group-block">
        <label>{{ filterElement.label | translate }} </label>
        <app-date-range-calendar
          [showLabel]="false"
          [minDate]="filterElement.metadata.min_date || ''"
          [maxDate]="filterElement.metadata.max_date || ''"
          [singleDate]="filters[filterElement.id]"
          (change)="setDateFilter(filterElement.id, $event)"
          [placeholder]="'GENERALS.SELECT-DATE' | translate"
        >
        </app-date-range-calendar>
      </div>
    </div>

    <div
      class="advance-search-item incurred-date filter-select-date"
      *ngIf="valueFilterOptions && valueFilterOptions[filterElement.id]"
    >
      <div class="select-group-block">
        <label>{{ filterElement.label | translate }} </label>
        <div class="flex date-select">
          <input
            #numberInput
            class="calendar-input-control text-left"
            type="number"
            [min]="filterElement.metadata.min_value"
            [max]="filterElement.metadata.max_value"
            (keyup)="
              setValueFilter(filterElement.id, filters[filterElement.id])
            "
            [(ngModel)]="filters[filterElement.id]"
            [placeholder]="'GENERALS.SELECT-VALUE' | translate"
          />
        </div>
      </div>
    </div>

    <div
      class="advance-search-item select-group-block filter-select-date"
      *ngIf="dateRangeFilterOptions && dateRangeFilterOptions[filterElement.id]"
    >
      <label> {{ filterElement.label | translate }} </label>

      <mat-form-field
        appearance="outline"
        class="w-full input-parent mat-form-date-range tasks-filter-date"
      >
        <mat-date-range-input
          [rangePicker]="picker"
          [min]="filterElement.metadata?.start_date"
          [max]="filterElement.metadata?.end_date"
        >
          <input
            matStartDate
            #start
            [ngModel]="dateRangeSelectedOptions[filterElement.id]?.start_date"
            placeholder="dd/mm/yyyy "
          />
          <input
            matEndDate
            #end
            [ngModel]="dateRangeSelectedOptions[filterElement.id]?.end_date"
            placeholder="dd/mm/yyyy"
          />
        </mat-date-range-input>
        <button
          matIconSuffix
          mat-icon-button
          *ngIf="dateRangeSelectedOptions[filterElement.id]"
          (click)="clearDateRange(filterElement.id)"
        >
          <mat-icon class="!p-0"> close </mat-icon>
        </button>
        <mat-datepicker-toggle
          matIconSuffix
          [for]="picker"
        ></mat-datepicker-toggle>
        <mat-date-range-picker #picker>
          <mat-date-range-picker-actions>
            <button mat-button matDateRangePickerCancel>Cancel</button>
            <button
              (click)="
                setActiveDateRange(filterElement.id, start.value, end.value)
              "
              mat-raised-button
              color="primary"
              matDateRangePickerApply
            >
              Apply
            </button>
          </mat-date-range-picker-actions>
        </mat-date-range-picker>
      </mat-form-field>
    </div>
  </ng-container>

  <!-- period selection -->
  <ng-container *ngIf="showCalendar">
    <div class="advance-search-item filter-select-date select-group-block">
      <label>{{ "FILTERS.DATE_RANGE" | translate }} </label>
      <div>
        <app-date-range-calendar
          [showLabel]="false"
          [multiple]="true"
          [dateRange]="dateRangeMultipleMonths"
          [placeholder]="'GENERALS.SELECT-DATE' | translate"
          (change)="setCalendarFilter($event)"
        >
        </app-date-range-calendar>
      </div>
      <div *ngIf="showClearBtn" class="float-left calendar-clear-inline">
        <div
          class="orange-bg btn-small"
          (click)="clearFilterData()"
          (keyup)="clearFilterData()"
        >
          {{ "GENERALS.CLEAR-DATE" | translate }}
        </div>
      </div>
    </div>
  </ng-container>
  <!-- end period selection -->
</div>
<!-- end toggle advanced tools for search (filters)-->
