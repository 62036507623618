import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { QuoteMapperService } from "@modules/quotes/services/quote-mapper.service";
import { PageData } from "@modules/shared/models/page.model";
import { QuoteModel } from "@modules/quotes/models/quote.model";
import { Observable } from "rxjs";
import { map, tap } from "rxjs/operators";
import { environment } from "@environment/environment";
import { CreateQuoteOrderData } from "@modules/quotes/models/create-quote-order.model";
import { CreatedOrderModel } from "@modules/quotes/models/created-order.model";
import { CreatedOrderMapperService } from "@modules/quotes/services/created-order-mapper.service";
import {
  QuotesSentToClientModel,
  QuotesSentToClientResponse,
} from "../models/quotes-sent-to-client.model";
import { CreateOrderServiceData } from "../models/create-order-service.model";

@Injectable({
  providedIn: "root",
})
export class QuotesService {
  private url = "entities/providers";

  public model: QuoteModel;

  constructor(
    private http: HttpClient,
    private mapper: QuoteMapperService,
    private orderMapper: CreatedOrderMapperService
  ) {}

  /**
   * List the client's custom quotes
   * @param providerId
   * @param clientId
   * @param params
   */
  public list(
    providerId: number,
    clientId: number,
    params = {}
  ): Observable<PageData<QuoteModel>> {
    return this.http
      .get<any>(
        `${environment.gateway_endpoint}${this.url}/${providerId}/clients/${clientId}/quotes`,
        { params }
      )
      .pipe(
        map((response) => {
          const { items, ...pageData } = response.result;
          return {
            ...pageData,
            items: this.mapper.mapMany(items),
          } as PageData<QuoteModel>;
        })
      );
  }

  /**
   * Add a new quote to the client
   */
  create(
    providerId: number,
    clientId: number,
    quote: QuoteModel
  ): Observable<any> {
    return this.http
      .post(
        environment.gateway_endpoint +
          this.url +
          `/${providerId}/clients/${clientId}/quotes`,
        quote
      )
      .pipe(
        tap((data) => {
          this.model = data;
        })
      );
  }
  /**
   * Add a new quote to the client
   */
  update(
    providerId: number,
    clientId: number,
    quote: QuoteModel
  ): Observable<any> {
    return this.http
      .put(
        environment.gateway_endpoint +
          this.url +
          `/${providerId}/clients/${clientId}/quotes/${quote.id}`,
        quote
      )
      .pipe(
        tap((data) => {
          this.model = data;
        })
      );
  }

  /**
   * Remove a quote from the client
   */
  delete(
    providerId: number,
    clientId: number,
    quote: QuoteModel
  ): Observable<any> {
    return this.http.delete(
      environment.gateway_endpoint +
        this.url +
        `/${providerId}/clients/${clientId}/quotes/${quote.id}`
    );
  }

  order(data: CreateQuoteOrderData): Observable<CreatedOrderModel> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}entities/providers/${data.provider_id}/clients/${data.client_id}/quotes/order`,
        data
      )
      .pipe(
        map((response) => {
          return this.orderMapper.mapMany(response.result.orders)[0];
        })
      );
  }

  createOrderServices(
    orderId: number,
    data: CreateOrderServiceData
  ): Observable<any> {
    return this.http
      .post<any>(
        `${environment.gateway_endpoint}entities/providers/${data.provider_id}/clients/${data.client_id}/quotes/${orderId}/services`,
        data
      )
      .pipe(
        map((response) => {
          return response.result;
        })
      );
  }

  export(params = {}) {
    return this.http.get(
      `${environment.gateway_endpoint}entities/quotes/export`,
      {
        responseType: "blob",
        params: params,
      }
    );
  }

  sendQuotesToClient(
    providerId: number,
    clientId: number,
    orderId: number
  ): Observable<QuotesSentToClientModel> {
    return this.http
      .post<QuotesSentToClientResponse>(
        `${environment.gateway_endpoint}${this.url}/${providerId}/clients/${clientId}/quotes/send-quotes-to-client`,
        { order_id: orderId }
      )
      .pipe(map((response) => ({ message: response.message })));
  }
}
