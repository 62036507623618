import { Component, Inject, OnInit, OnDestroy } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { BillingDetails } from "@api/clients/models/billing-details.models";
import { ClientsService } from "@api/clients/services";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import {
  Observable,
  Subject,
  combineLatest,
  debounceTime,
  finalize,
  map,
  of,
  shareReplay,
  startWith,
  switchMap,
  takeUntil,
} from "rxjs";
import { Country } from "src/app/_models/country";
import { AlertService } from "src/app/_services/alert.service";
import { DictionariesService } from "src/app/_services/dictionaries.service";

@Component({
  selector: "app-client-billing-dialog",
  templateUrl: "./client-billing-dialog.component.html",
  styleUrls: ["./client-billing-dialog.component.scss"],
})
export class ClientBillingDialogComponent implements OnInit, OnDestroy {
  details: BillingDetails;
  edit: boolean = false;
  entityId: number;

  billingDetailsForm = new FormGroup({
    name: new FormControl("", Validators.required),
    address: new FormControl("", Validators.required),
    city: new FormControl("", Validators.required),
    region: new FormControl("", Validators.required),
    country_code: new FormControl("", Validators.required),
    zip_code: new FormControl(""),
    tax_number: new FormControl("", Validators.required),
    bank_name: new FormControl(""),
    swift_code: new FormControl("", Validators.maxLength(12)),
    bank_account_number: new FormControl(""),
  });

  countrySearchControl = new FormControl();
  citySearchControl = new FormControl();
  filteredCountries$: Observable<Country[]>;
  countries$: Observable<Country[]>;

  cities$: Observable<any>;

  isLoading: boolean = false;

  private unsubscribe$: Subject<void> = new Subject<void>();

  constructor(
    private dialogRef: MatDialogRef<ClientBillingDialogComponent>,
    private alertService: AlertService,
    private dictionary: DictionariesService,
    private clientService: ClientsService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      billingDetails: BillingDetails;
      entityId: number;
    }
  ) {
    if (data.billingDetails) {
      this.details = { ...data.billingDetails };
      this.edit = true;
    }
    this.entityId = data.entityId;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngOnInit(): void {
    if (this.data.billingDetails) {
      this.billingDetailsForm.patchValue(this.data.billingDetails);
    }

    this.cities$ = combineLatest([
      this.citySearchControl.valueChanges.pipe(
        startWith(this.data.billingDetails?.city ?? "")
      ),
      this.billingDetailsForm
        .get("country_code")
        .valueChanges.pipe(
          startWith(this.data.billingDetails?.country_code ?? "")
        ),
    ]).pipe(
      debounceTime(500),
      switchMap(([city, country]) => {
        if (country) {
          return this.dictionary.getCities(country, city);
        }

        return of({ result: [] });
      }),
      map((cities) => cities.result)
    );

    this.billingDetailsForm.get("country_code").valueChanges.subscribe(() => {
      this.billingDetailsForm.get("city").setValue("");
      this.citySearchControl.setValue("");
    });

    this.countries$ = this.dictionary
      .getCountryList()
      .pipe(map((data) => data.result));

    this.filteredCountries$ = combineLatest([
      this.countrySearchControl.valueChanges.pipe(startWith("")),
      this.countries$,
    ]).pipe(
      map(([searchInput, countries]) => {
        if (!searchInput) {
          return countries;
        }
        const filterValue = searchInput.toLowerCase();
        return countries.filter((country) =>
          country.name.toLowerCase().includes(filterValue)
        );
      }),
      shareReplay(1)
    );
  }

  updateOrCreate(body: BillingDetails) {
    if (this.edit) {
      return this.clientService.updateBillingAddress(this.entityId, {
        ...this.details,
        ...body,
      });
    }
    return this.clientService.addBillingAddress(this.entityId, body);
  }

  save(): void {
    this.billingDetailsForm.markAllAsTouched();

    if (!this.billingDetailsForm.valid) {
      return;
    }
    this.isLoading = true;
    const body = this.billingDetailsForm.getRawValue();
    this.updateOrCreate(body)
      .pipe(
        takeUntil(this.unsubscribe$),
        finalize(() => (this.isLoading = false))
      )
      .subscribe({
        next: (res) => {
          if (res.message) {
            this.alertService.success(res.message);
          } else if (res.messages) {
            this.alertService.success(res.messages);
          }
          this.dialogRef.close(res);
        },
        error: (error) => {
          if (error.error.errors) {
            this.alertService.errors(error.error.errors);
          }
        },
      });
  }

  close(): void {
    this.dialogRef.close();
  }

  public get LoadingType(): typeof LoadingTypeEnum {
    return LoadingTypeEnum;
  }
}
