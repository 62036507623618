import { FilterRequestData } from "./../../../models/filter-request.model";
import { HttpClient } from "@angular/common/http";
import {
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  inject,
  Input,
  OnChanges,
  OnInit,
  Output,
  QueryList,
  Renderer2,
  SimpleChanges,
  ViewChildren,
  ViewEncapsulation,
} from "@angular/core";
import { UntypedFormControl } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import moment from "moment";
import {
  debounceTime,
  distinctUntilChanged,
  filter,
  switchMap,
} from "rxjs/operators";
import { FilterTypesEnum } from "../../../../../_enums/filter-types-enum";
import { ItemsListComponent } from "../items-list.component";
import { FilterService } from "@modules/shared/services/filter.service";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { BehaviorSubject } from "rxjs";

@Component({
  selector: "app-filters",
  templateUrl: "./filters.component.html",
  styleUrls: ["./filters.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class FiltersComponent
  extends ItemsListComponent
  implements OnInit, OnChanges
{
  public keyword: any;
  public filterOptions = {};
  public multipleFilterOptions = {};
  public dateFilterOptions = {};
  public dateRangeFilterOptions = {};
  public dateRangeSelectedOptions = {};
  public valueFilterOptions = {};
  dateRangeMultipleMonths;
  timeFrameMultipleMonths; // deprecated

  public filters = {
    search_fields: null,
    page: 1,
  };
  showClearBtn = false;
  showTimeFrameClearBtn = false;
  showAdvanced = false;

  @Input() showTextField = true;
  @Input() availableFilters;
  @Input() activeFilters;
  @Input() showCalendar = false;
  @Input() searchPlaceholder = "";
  @Input() total = 0;

  @Output() changePerPage: EventEmitter<number> = new EventEmitter();
  @Output() eventClearFilters: EventEmitter<void> = new EventEmitter();
  @Output() search: EventEmitter<any> = new EventEmitter();
  @Output() filter: EventEmitter<any> = new EventEmitter();
  @Output() date: EventEmitter<any> = new EventEmitter();

  keywordFormControl: UntypedFormControl = new UntypedFormControl();

  private destroyRef = inject(DestroyRef);
  private searchTerms = new BehaviorSubject<{
    term: string;
    filter: any;
  } | null>(null);

  loadPage: number = 1;
  searchText: string = "";

  constructor(
    private http: HttpClient,
    public renderer: Renderer2,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private filterService: FilterService
  ) {
    super();
  }

  init() {
    this.filters = {
      search_fields: null,
      page: 1,
    };
    this.keyword = null;
  }

  ngOnInit() {
    this.keywordFormControl.valueChanges
      .pipe(debounceTime(600))
      .subscribe((value) => {
        this.emitKeyword(value);
      });

    this.route.queryParams.subscribe((params) => {
      this.keywordFormControl.setValue(params["search_text"], {
        emitEvent: false,
      });
    });
    this.initSearchFilter();
  }

  @ViewChildren("numberInput") numberInputs: QueryList<ElementRef>;

  public get previewFilters() {
    const filters = [];

    if (this.activeFilters) {
      for (const [key, value] of Object.entries(this.activeFilters)) {
        if (!["page", "per_page", "search_fields"].includes(key)) {
          if (this.dateRangeFilterOptions[key]) {
            this.dateRangeSelectedOptions[key] = {
              start_date: value.toString().split(";")[0],
              end_date: value.toString().split(";")[1],
            };
            this.filters[key] = value;
          }

          if (this.multipleFilterOptions[key]) {
            const getLabel = () => {
              const element = this.availableFilters?.find(
                (element) => element.id == key
              );
              if (!element?.label) {
                return "";
              }
              return this.translateService.instant(element?.label);
            };

            let text = "";
            if (Array.isArray(value)) {
              const itemsToRemove = [];

              text = value
                .map((item) => {
                  const option = this.multipleFilterOptions[key]?.find(
                    (element) => element.id === item
                  );

                  if (
                    !option?.text &&
                    this.filters[key] &&
                    Array.isArray(this.filters[key])
                  ) {
                    itemsToRemove.push(item);
                  }
                  return option?.text;
                })
                .filter((text) => text)
                .join(", ");

              if (itemsToRemove.length && Array.isArray(this.filters[key])) {
                this.filters[key] = this.filters[key].filter(
                  (item) => !itemsToRemove.includes(item)
                );
                this.filter.emit(this.filters);
              }
            } else {
              text = this.multipleFilterOptions[key]?.find(
                (element) => element.id == value
              )?.text;
            }

            filters.push({
              key: this.availableFilters && getLabel(),
              text: text,
            });
          }

          if (
            !Array.isArray(this.activeFilters[key]) &&
            isNaN(this.activeFilters[key]) &&
            (moment(this.activeFilters[key], moment.ISO_8601).isValid() ||
              moment(
                this.activeFilters[key].split(";")[0],
                moment.ISO_8601
              ).isValid())
          ) {
            const text = this.activeFilters[key].includes(";")
              ? moment(this.activeFilters[key].split(";")[0]).format("D/MM/Y") +
                "-" +
                moment(this.activeFilters[key].split(";")[1]).format("D/MM/Y")
              : moment(this.activeFilters[key]).format("D/MM/Y");

            filters.push({
              key: key
                .split("_")
                .map((key) => key.charAt(0).toUpperCase() + key.slice(1))
                .join(" "),
              text,
            });

            continue;
          }

          if (
            this.availableFilters?.length &&
            this.filterOptions[key]?.length
          ) {
            const getTranslatedLabel = () => {
              const element = this.availableFilters?.find(
                (element) => element.id == key
              );
              if (!element?.label) {
                return "";
              }
              return this.translateService.instant(element?.label);
            };

            filters.push({
              key: this.availableFilters && getTranslatedLabel(),
              text: this.filterOptions[key]?.find(
                (element) => element.id == value
              )?.text,
            });
          }
        }
      }
    }

    return filters;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.availableFilters && Array.isArray(this.availableFilters)) {
      this.availableFilters = this.availableFilters.map((filter) => {
        const data = filter.data
          ?.filter((data) => !!data.text)
          .map((data) => {
            return { ...data, text: this.translateService.instant(data.text) };
          });
        return { ...filter, data };
      });
      this.availableFilters.forEach((filter) => {
        switch (filter.field_type) {
          case FilterTypesEnum.MULTIPLE_SELECT:
            this.multipleFilterOptions[filter.id] = filter.data;
            break;
          case FilterTypesEnum.DATE_INTERVAL:
            this.dateFilterOptions[filter.id] = filter.metadata;
            break;
          case FilterTypesEnum.DATE_RANGE:
            this.dateRangeFilterOptions[filter.id] = filter.metadata;
            break;
          case FilterTypesEnum.VALUE_INTERVAL:
            this.valueFilterOptions[filter.id] = filter.metadata;
            break;
          default:
            if (filter.data) {
              this.filterOptions[filter.id] = filter.data;
            } else if (filter.service) {
              filter.service[filter.method]().subscribe((data) => {
                if (data.success) {
                  this.filterOptions[filter.id] = data.result;
                }
              });
            }
            break;
        }
      });

      this.setActiveTimeFrame();
    }

    if (this.activeFilters) {
      this.init();

      if (this.availableFilters && Array.isArray(this.availableFilters)) {
        this.availableFilters.forEach((f) => {
          if (this.activeFilters[f.id]) {
            switch (f.field_type) {
              case FilterTypesEnum.MULTIPLE_SELECT:
                if (!Array.isArray(this.activeFilters[f.id])) {
                  this.filters[f.id] = [
                    !isNaN(this.activeFilters[f.id])
                      ? parseInt(this.activeFilters[f.id])
                      : this.activeFilters[f.id],
                  ];
                } else {
                  this.filters[f.id] = this.activeFilters[f.id].map(
                    (filter) => {
                      if (!isNaN(parseInt(filter))) {
                        return parseInt(filter);
                      } else {
                        return filter;
                      }
                    }
                  );
                }
                break;
              default:
                if (!isNaN(this.activeFilters[f.id])) {
                  this.filters[f.id] = +this.activeFilters[f.id];

                  break;
                }

                this.filters[f.id] = this.activeFilters[f.id];

                break;
            }
          }
        });
      }

      if (this.activeFilters["search_text"] && !this.keywordFormControl.value) {
        this.keyword = this.activeFilters["search_text"];
        this.keywordFormControl.setValue(this.activeFilters["search_text"], {
          emitEvent: false,
        });
      }
      if (this.activeFilters["status_id"]) {
        this.filters["status_id"] = parseInt(
          this.activeFilters["status_id"],
          10
        );
      }
      if (this.activeFilters["start_date"] && this.activeFilters["end_date"]) {
        this.dateRangeMultipleMonths = {
          start_date: this.formatDataParam(this.activeFilters["start_date"]),
          end_date: this.formatDataParam(this.activeFilters["end_date"]),
        };
        this.showClearBtn = true;
      } else {
        this.dateRangeMultipleMonths = null;
        this.showClearBtn = false;
      }
    }
  }

  private setActiveTimeFrame() {
    if (this.activeFilters && this.activeFilters["time_frame"]) {
      const startDate = this.activeFilters["time_frame"].split(";")[0];
      const endDate = this.activeFilters["time_frame"].split(";")[1];

      this.timeFrameMultipleMonths = {
        start_date: this.formatDataParam(startDate),
        end_date: this.formatDataParam(endDate),
      };

      this.showTimeFrameClearBtn = true;

      return;
    }

    this.timeFrameMultipleMonths = {
      start_date: null,
      end_date: null,
    };

    this.showTimeFrameClearBtn = false;
  }

  setCalendarFilter(e) {
    if (e.start_date || e.end_date) {
      if (e.start_date !== null || e.end_date !== null) {
        this.date.emit(this.setDate(e, true));
      }
    }
  }

  clearFilterData() {
    const ev = {
      start_date: "null",
      end_date: "null",
    };
    this.date.emit(this.setDate(ev, "clearFilterDate"));
  }

  toggleAdvanceSearch() {
    this.showAdvanced = !this.showAdvanced;
  }

  emitKeyword(keyword) {
    this.search.emit(keyword);
  }

  formatDataParam(data) {
    const formatedData = moment(new Date(data)).format("YYYY/MM/DD");
    return formatedData.split("/").join("-");
  }

  setMultipleSelectFilter(id, select) {
    this.filters[id] = [];
    select.forEach((f) => {
      if (!isNaN(f.id)) {
        this.filters[id].push(parseInt(f.id));
      } else {
        this.filters[id].push(f.id);
      }
    });
    if (this.filters[id].length == 0) {
      delete this.filters[id];
    }
    this.filter.emit(this.filters);
  }

  setDateFilter(id, event) {
    const formatData = moment(new Date(event)).format("YYYY/MM/DD");
    this.filters[id] = formatData.split("/").join("-");
    this.filter.emit(this.filters);
  }

  setSelectFilter(select) {
    this.filter.emit(select);
  }

  setValueFilter(id, event) {
    this.filters[id] = event;
  }

  clearAllFilters(): void {
    this.keywordFormControl.setValue("", { emitEvent: false });
    this.perPage = 25;
    this.dateRangeSelectedOptions = {};

    this.eventClearFilters.emit();
  }

  setPerPage(): void {
    this.changePerPage.emit(this.perPage);
  }

  public setActiveDateRange(id, startDate, endDate?) {
    if (startDate) {
      let end = "";
      if (endDate) {
        end = `;${moment(endDate, "DD/MM/YYYY")
          .endOf("day")
          .format("yyyy-MM-DD")}`;
      }
      this.filters[id] =
        moment(startDate, "DD/MM/YYYY").endOf("day").format("yyyy-MM-DD") + end;
      this.filter.emit(this.filters);
    }
  }

  public clearDateRange(id) {
    delete this.filters[id];
    delete this.dateRangeSelectedOptions[id];
    this.filter.emit(this.filters);
  }

  getFilterItems(filter: any, params: FilterRequestData) {
    if (!filter.dataEndpoint) {
      return;
    }

    filter.isLoading = true;

    this.filterService
      .listFilterItems(filter.dataEndpoint, params)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data) => {
          filter.data = data;
        },
        complete: () => {
          filter.isLoading = false;
        },
      });
  }

  filterSearch(event: any, filter: any) {
    if (!filter.dataEndpoint) {
      return;
    }

    const searchTerm = event?.term?.toLowerCase() || "";
    this.searchText = searchTerm;
    this.loadPage = 1;

    this.searchTerms.next({ term: searchTerm, filter });
  }

  resetAndLoadFilterItems(filter: any): void {
    if (!filter.dataEndpoint) {
      return;
    }

    this.loadPage = 1;

    const params: FilterRequestData = {
      name: filter.id,
      page: 1,
      endpoint: filter.dataEndpoint,
    };

    this.getFilterItems(filter, params);
  }

  openFilter(filter: any): void {
    this.resetAndLoadFilterItems(filter);
  }

  clearFilter(filter: any): void {
    this.resetAndLoadFilterItems(filter);
  }

  loadMoreData(filter: any): void {
    if (!filter.canLoadMoreData || filter.isLoading) {
      return;
    }

    filter.isLoading = true;

    const params: FilterRequestData = {
      name: filter.id,
      page: ++this.loadPage,
      endpoint: filter.dataEndpoint,
    };

    if (this.searchText) {
      params.search_text = this.searchText;
    }

    this.filterService
      .listFilterItems(filter.dataEndpoint, params)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (newData) => {
          filter.data = [...filter.data, ...newData];

          filter.canLoadMoreData = newData.length > 0;
        },
        complete: () => {
          filter.isLoading = false;
        },
      });
  }

  initSearchFilter() {
    this.searchTerms
      .pipe(
        filter((searchParams) => !!searchParams),
        debounceTime(300),
        distinctUntilChanged((prev, curr) => prev!.term === curr!.term),
        switchMap(({ term, filter }) => {
          const params: FilterRequestData = {
            name: filter.id,
            page: 1,
            endpoint: filter.dataEndpoint,
          };

          if (term) {
            params.search_text = term;
          }

          filter.isLoading = true;

          return this.filterService.listFilterItems(
            filter.dataEndpoint,
            params
          );
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (data) => {
          const latestSearch = this.searchTerms.getValue();
          if (latestSearch) {
            latestSearch.filter.data = data;
            latestSearch.filter.isLoading = false;
          }
        },
      });
  }
}
