import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { AlertService } from "../../../../_services/alert.service";
import { Component, DestroyRef, inject } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SocialAccountService } from "@modules/shared/services/social-account.service";
import { ConfirmActionModalComponent } from "../confirm-action-modal/confirm-action-modal.component";
import { MatDialog } from "@angular/material/dialog";
import { catchError, finalize, Observable, of } from "rxjs";
import { TranslateService } from "@ngx-translate/core";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { SocialAccount } from "@api/emails/models/social-account.model";
import { SocialDriverTypeEnum } from "@modules/shared/_enums/social-driver-type.enum";

@Component({
  selector: "app-link-social-account",
  templateUrl: "./link-social-account.component.html",
  styleUrls: ["./link-social-account.component.scss"],
})
export class LinkSocialAccountComponent {
  socialAccount$: Observable<SocialAccount>;

  private destroyRef = inject(DestroyRef);

  isLoading = false;

  protected readonly LoadingTypeEnum = LoadingTypeEnum;
  protected readonly SocialDriverTypeEnum = SocialDriverTypeEnum;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private socialAccountService: SocialAccountService,
    private alertService: AlertService,
    private dialog: MatDialog,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.activatedRoute.queryParams
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((params) => {
        const code = params.code;

        if (code) {
          const driver = localStorage.getItem("linked_driver");

          if (driver) {
            this.callback(driver, code);
          }
        }
      });

    this.getSocialAccount();
  }

  getSocialAccount() {
    this.isLoading = true;

    this.socialAccount$ = this.socialAccountService.getAccount().pipe(
      catchError((error) => {
        this.alertService.error(error?.error?.errors);
        return of(null);
      }),
      finalize(() => {
        this.isLoading = false;
      })
    );
  }

  linkAccount(driver: string) {
    localStorage.setItem("current_url", this.router.url);

    this.socialAccountService
      .linkAccount(driver, `${window.location.origin}/my-account`)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data) => {
          localStorage.setItem("linked_driver", driver);

          window.location.href = data;
        },
        error: (error) => {
          this.clearUrl();
          this.alertService.error(error?.errors.message);
        },
      });
  }

  callback(driver: string, code: string) {
    this.isLoading = true;

    this.socialAccountService
      .callback(driver, code, `${window.location.origin}/my-account`)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.alertService.success(
            this.translate.instant("EMAIL.CONNECTED_SUCCESSFULLY")
          );
          this.socialAccount$ = of(response.result.social_account);

          const currentUrl = localStorage.getItem("current_url");

          this.router.navigateByUrl(currentUrl);
        },
        error: (error) => {
          this.clearUrl();
          this.alertService.error(error?.errors[0]?.message);
          this.isLoading = false;
        },
      });
  }

  confirmUnlink() {
    this.dialog
      .open(ConfirmActionModalComponent, {
        data: {
          action: {
            needs_confirmation: true,
            text: this.translate.instant("EMAIL.DISCONNECT_SOCIAL_ACCOUNT"),
          },
        },
      })
      .afterClosed()
      .subscribe((result) => {
        if (result) {
          this.unlinkAccount();
        }
      });
  }

  unlinkAccount() {
    this.socialAccountService
      .unlinkAccount()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.alertService.success(response);
          this.socialAccount$ = of(null);
        },
        error: (error) => {
          this.alertService.error(error?.error?.errors);
        },
      });
  }

  clearUrl() {
    localStorage.removeItem("linked_driver");
    localStorage.removeItem("current_url");

    const keysToRemove = ["code", "redirect_url", "scope", "driver"];
    const queryParams = { ...this.activatedRoute.snapshot.queryParams };

    keysToRemove.forEach((key) => delete queryParams[key]);
  }
}
