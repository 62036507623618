import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "emailRecipients",
})
export class EmailRecipientsPipe implements PipeTransform {
  transform(recipients: any[], type: string): string {
    if (!recipients || !Array.isArray(recipients)) {
      return "";
    }

    return recipients
      .filter((recipient) => recipient.type === type)
      .map((recipient) => recipient.email_address)
      .join(", ");
  }
}
