import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "@environment/environment";
import { SocialAccount } from "@api/emails/models/social-account.model";
import { map, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class SocialAccountService {
  constructor(private http: HttpClient) {}

  linkAccount(driver: string, redirectUrl: string): Observable<any> {
    return this.http
      .get(`${environment.gateway_endpoint}socialite/auth/${driver}`, {
        params: {
          redirect_url: redirectUrl,
        },
      })
      .pipe(map((response: any) => response.result));
  }

  callback(driver: string, code: string, redirectUrl: string): Observable<any> {
    return this.http.post(
      `${environment.gateway_endpoint}socialite/auth/${driver}/callback`,
      {
        code,
        redirect_url: redirectUrl,
      }
    );
  }

  getAccount(): Observable<SocialAccount | null> {
    return this.http
      .get(`${environment.gateway_endpoint}socialite/accounts`)
      .pipe(map((response: any) => response.result.social_account));
  }

  unlinkAccount(): Observable<any> {
    return this.http
      .delete(`${environment.gateway_endpoint}socialite/accounts`, {})
      .pipe(map((response: any) => response.result));
  }
}
