import { Component, DestroyRef, inject, Inject } from "@angular/core";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { FormArray, FormGroup, Validators } from "@angular/forms";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { OrderData } from "@api/billing";
import { CartItemForm } from "@modules/quotes/components/create-order/items-step/items-step.component";
import { CreateOrderServiceData } from "@modules/quotes/models/create-order-service.model";
import { QuotesService } from "@modules/quotes/services/quotes.service";
import { LoadingTypeEnum } from "@modules/shared/_enums/loading-type.enum";
import { TranslateService } from "@ngx-translate/core";
import { AlertService } from "@services/alert.service";
import { finalize } from "rxjs";

@Component({
  selector: "app-create-service",
  templateUrl: "./create-service.component.html",
  styleUrls: ["./create-service.component.scss"],
})
export class CreateServiceComponent {
  itemsForm: FormArray<CartItemForm> = new FormArray<FormGroup>(
    [],
    Validators.compose([Validators.required, Validators.minLength(1)])
  );

  isLoading: boolean = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    private quoteService: QuotesService,
    private alertService: AlertService,
    private translate: TranslateService,
    private dialogRef: MatDialogRef<CreateServiceComponent>,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: {
      order: OrderData;
    }
  ) {}

  get order() {
    return this.dialogData.order;
  }

  get itemsFormLength(): number {
    return this.itemsForm.length;
  }

  get LoadingType() {
    return LoadingTypeEnum;
  }

  submit() {
    const data: CreateOrderServiceData = {
      quotes: this.itemsForm.getRawValue().map((quote) => ({
        id: quote.id,
        quantity: quote.quantity,
      })),
      client_id: this.order.client.id,
      provider_id: this.order.provider_id,
      currency_code: this.itemsForm.value[0].currency,
    };

    this.isLoading = true;

    this.quoteService
      .createOrderServices(this.order.id, data)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        }),
        takeUntilDestroyed(this.destroyRef)
      )
      .subscribe({
        next: (order) => {
          this.dialogRef.close(order);
          this.alertService.success(
            this.translate.instant("PAGES.ORDERS.ORDER_SERVICES_ADDED")
          );
        },
        error: (message) => {
          this.alertService.error(message);
        },
      });
  }
}
