<div class="billing-dialog dialog p-0">
  <app-dialog-header>
    {{
      (edit ? "BILLING.EDIT-BILLING-ADDRESS" : "BILLING.ADD-BILLING-ADDRESS")
        | translate
    }}
  </app-dialog-header>

  <div class="dialog-body">
    <form [formGroup]="billingDetailsForm">
      <div class="field-grid">
        <!-- name -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.COMPANY-NAME" | translate }} </mat-label>
          <input matInput formControlName="name" name="name" />
          <mat-error formControlError="name"></mat-error>
        </mat-form-field>
        <!-- tax_number -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.TAX-NUMBER" | translate }} </mat-label>
          <input matInput formControlName="tax_number" name="tax_number" />
          <mat-error formControlError="tax_number"></mat-error>
        </mat-form-field>
        <!-- country_code -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-select
            formControlName="country_code"
            placeholder="{{ 'LABEL.COUNTRY' | translate }} *"
            #singleSelect
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'LABEL.COUNTRY' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="countrySearchControl"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let country of filteredCountries$ | async"
              [value]="country.country_code"
            >
              {{ country.name }}
            </mat-option>
          </mat-select>
          <mat-error formControlError="country_code"></mat-error>
        </mat-form-field>
        <!-- region -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.REGION" | translate }} </mat-label>
          <input matInput formControlName="region" name="region" />
          <mat-error formControlError="region"></mat-error>
        </mat-form-field>
        <!-- city -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-select
            formControlName="city"
            placeholder="{{ 'LABEL.CITY' | translate }} *"
            #singleSelect
          >
            <mat-option>
              <ngx-mat-select-search
                [placeholderLabel]="'LABEL.CITY' | translate"
                [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
                [formControl]="citySearchControl"
                [clearSearchInput]="false"
              ></ngx-mat-select-search>
            </mat-option>
            <mat-option
              *ngFor="let city of cities$ | async"
              [value]="city.name"
            >
              {{ city.name }}
            </mat-option>
          </mat-select>
          <mat-error formControlError="city"></mat-error>
        </mat-form-field>
        <!-- address -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.ADDRESS" | translate }} </mat-label>
          <input matInput formControlName="address" name="address" />
          <mat-error formControlError="address"></mat-error>
        </mat-form-field>
        <!-- zip_code -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.ZIP-CODE" | translate }} </mat-label>
          <input matInput formControlName="zip_code" name="zip_code" />
          <mat-error formControlError="zip_code"></mat-error>
        </mat-form-field>
        <!-- bank_name -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.BANK-NAME" | translate }} </mat-label>
          <input matInput formControlName="bank_name" name="bank_name" />
          <mat-error formControlError="bank_name"></mat-error>
        </mat-form-field>
        <!-- swift_code -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.SWIFT-CODE" | translate }} </mat-label>
          <input matInput formControlName="swift_code" name="swift_code" />
          <mat-error formControlError="swift_code"></mat-error>
        </mat-form-field>
        <!-- bank_account_number -->
        <mat-form-field appearance="outline" class="w-full">
          <mat-label> {{ "LABEL.BANK-ACCOUNT-NUMBER" | translate }} </mat-label>
          <input
            matInput
            formControlName="bank_account_number"
            name="bank_account_number"
          />
          <mat-error formControlError="bank_account_number"></mat-error>
        </mat-form-field>
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <button class="btn-primary" (click)="save()" [disabled]="isLoading">
      <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
