<app-dialog-header>
  {{ "PAGES.ORDERS.ADD_SERVICES_TO_ORDER" | translate }}
</app-dialog-header>

<div class="dialog-body">
  <app-items-step
    [providerId]="order.provider_id"
    [clientId]="order.client.id"
    [orderDetails]="order.details"
    [itemsForm]="itemsForm"
  >
  </app-items-step>
</div>

<div class="dialog-footer">
  <button
    type="button"
    class="btn-primary"
    (click)="submit()"
    [disabled]="isLoading || itemsFormLength === 0"
  >
    <mat-icon svgIcon="check-outline" class="btn-icon"></mat-icon>
    {{ "GENERALS.SAVE" | translate }}
  </button>
</div>
