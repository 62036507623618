import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from "@angular/common/http";
import { LOCALE_ID, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { NoopAnimationsModule } from "@angular/platform-browser/animations";
import { PermissionMapperService } from "@api/users/services/permission-mapper.service";
import { PermissionsService } from "@api/users/services/permissions.service";
import { ResourcePermissionMapperService } from "@api/users/services/resource-permission-mapper.service";
import { AuthorizationModule } from "@modules/authorization/authorization.module";
import { CasesSharedModule } from "@modules/cases/cases-shared/cases-shared.module";
import { QuotesModule } from "@modules/quotes/quotes.module";
import { EffectsModule } from "@ngrx/effects";
import { ActionReducer, MetaReducer, StoreModule } from "@ngrx/store";
import { StoreDevtoolsModule } from "@ngrx/store-devtools";
// ########## LIBRARY ##########
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from "@ngx-translate/core";
import { TranslateHttpLoader } from "@ngx-translate/http-loader";
import { CookieService } from "ngx-cookie-service";
import { AppRoutingModule } from "./app-routing.module";
import { AppComponent } from "./app.component";
import { AccessDeniedPageComponent } from "./components/access-denied/access-denied.component";
import { AccountDocumentsComponent } from "./components/account-settings/account-documents/account-documents.component";
import { AccountSettingsComponent } from "./components/account-settings/account-settings.component";
import { AccountComponent } from "./components/account-settings/account/account.component";
import { UserAgreementsComponent } from "./components/account-settings/user-agreements/user-agreements.component";
import { AdsComponent } from "./components/ads/ads.component";
import { CreateAdBannerComponent } from "./components/ads/create-ad-banner/create-ad-banner.component";
import { DetailsCampaignComponent } from "./components/ads/details-campaign/details-campaign.component";
import { PreviewBannerComponent } from "./components/ads/preview-banner/preview-banner.component";
import { UpdateAdBannerComponent } from "./components/ads/update-ad-banner/update-ad-banner.component";
import { ConfirmEmailComponent } from "./components/auth/confirm-email/confirm-email.component";
import { CreateProfileComponent } from "./components/auth/create-profile/create-profile.component";
// ########## COMPONENTS ##########
import { LoginComponent } from "./components/auth/login/login.component";
import { RecoverPasswordComponent } from "./components/auth/recover-password/recover-password.component";
import { RegisterComponent } from "./components/auth/register/register.component";
import { ResetPasswordComponent } from "./components/auth/reset-password/reset-password.component";
import { AddCommissionsComponent } from "./components/auth/service-portfolio/add-commission/add-commissions.component";
import { ServicePortfolioComponent } from "./components/auth/service-portfolio/service-portfolio.component";
import { SsoComponent } from "./components/auth/sso/sso.component";
import { ChipsSelectComponent } from "./components/chips-select/chips-select.component";
import { CookieConsentComponent } from "./components/cookie-consent/cookie-consent.component";
import { AccountActivityComponent } from "./components/dashboard/account-activity/account-activity.component";
import { AddDocComponent } from "./components/dashboard/add-doc/add-doc.component";
import { CategoriesTreeComponent } from "./components/dashboard/categories-tree/categories-tree.component";
import { AddCategoriesComponent } from "./components/dashboard/categories/add-categories/add-categories.component";
import { ProviderCategoriesComponent } from "./components/dashboard/categories/provider-categories.component";

import { UsersListComponent } from "./components/dashboard/company-users/users-list/users-list.component";
import { ConfirmInvoicePaymentComponent } from "./components/dashboard/confirm-invoice-payment/confirm-invoice-payment.component";
import { DetailsComponent } from "./components/dashboard/details/details.component";
import { DocumentsComponent as DashBoardDocuments } from "./components/dashboard/documents/documents.component";
import { HeaderXsComponent } from "./components/dashboard/header/header-xs/header-xs.component";
import { HeaderComponent } from "./components/dashboard/header/header.component";
import { TopMenuComponent } from "./components/dashboard/header/top-menu/top-menu.component";
import { InvoiceCommissionsComponent } from "./components/dashboard/invoices/commissions/invoice-commissions.component";
import { InvoiceComponent } from "./components/dashboard/invoices/invoice/invoice.component";
import { InvoicesListComponent } from "./components/dashboard/invoices/list/invoices-list.component";
import { LeftNavigationXsComponent } from "./components/dashboard/left-navigation-xs/left-navigation-xs.component";
import { LeftNavigationComponent } from "./components/dashboard/left-navigation/left-navigation.component";

import { ClientDetailsModalComponent } from "src/app/components/dashboard/orders/client-details/client-details";
import { OrderActivityComponent } from "./components/dashboard/orders/order-activity/order-activity.component";
import { OrderDetailsComponent } from "./components/dashboard/orders/order-details/order-details.component";
import { OrderDocumentsComponent } from "./components/dashboard/orders/order-documents/order-documents.component";
import { OrderInvoicesComponent } from "./components/dashboard/orders/order-invoices/order-invoices.component";
import { OrderQuotesComponent } from "./components/dashboard/orders/order-quotes/order-quotes.component";
import { OrderComponent } from "./components/dashboard/orders/order/order.component";

import { PayInvoiceComponent } from "./components/dashboard/pay-invoice/pay-invoice.component";
import { PortalBreadcrumbComponent } from "./components/dashboard/portal-breadcrumbs/portal-breadcrumbs.component";

import { WidgetsEffects } from "./components/dashboard/state/effects/widget.effects";

import { NotFoundComponent } from "./components/not-found/not-found.component";
import { PrivacyComponent } from "./components/static/privacy/privacy.component";
import { TermsComponent } from "./components/static/terms/terms.component";
import { StatisticsDateFilterComponent } from "./components/statistics-date-filter/statistics-date-filter.component";
// ########## LAYOUT ##########
import { SharedModule } from "@modules/shared/shared.module";
import { AlertComponent } from "./_directives/alert/alert.component";
import { ExpandMenuDirective } from "./_directives/expand-menu";
import { AuthLayoutComponent } from "./layout/auth-layout/auth-layout.component";
import { DashboardLayoutComponent } from "./layout/dashboard-layout/dashboard-layout.component";
import { StaticLayoutComponent } from "./layout/static-layout/static-layout.component";
// ########## DIRECTIVES ##########
import { ToggleRowDirective } from "./_directives/toggle-row";
import { TranslateComponent } from "./_directives/translate/translate.component";
import { WindowResizeDirective } from "./_directives/window-resize";
import { WindowScrollDashboardBodyActionDirective } from "./_directives/window-scroll-dashboardBodyAction";
import { WindowScrollTableBulkDirective } from "./_directives/window-scroll-table-bulk";
import { AuthGuard } from "./_guards/auth.guard";
import { ProfileGuard } from "./_guards/profile.guard";
// ########## HELPERS ##########
import { CategoryHelper } from "@helpers/_models/category.helper";
import { FilesHelper } from "@helpers/_models/files.helper";
import { RfqHelper } from "@helpers/_models/rfq.helper";
import { CalendarHelper } from "@helpers/calendar.helper";
import { CompareHelper } from "@helpers/compare.helper";
import { CurrentUserHelper } from "@helpers/current.user.helper";
import { ErrorInterceptor } from "@helpers/error.interceptor";
import { JwtInterceptor } from "@helpers/jwt.interceptor";

import { ActivityService } from "./_services/activity.service";
import { AdsService } from "./_services/ads.service";
import { AlertService } from "./_services/alert.service";
// ########## SERVICES ##########
import { AuthenticationService } from "./_services/authentication.service";
import { BreadcrumbService } from "./_services/breadcrumb.service";

import { CommonModule, NgOptimizedImage } from "@angular/common";
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from "@angular/material/form-field";
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from "@angular/material/snack-bar";
import { BACKEND_API_HTTP_INTERCEPTORS } from "@api/http/backend-http-client";
import { AuthInterceptor } from "@api/http/interceptors/auth.interceptor";
import { environment } from "@environment/environment";
import { GetArrayInterceptor } from "@helpers/get-array.interceptor";
import { Drivers } from "@ionic/storage";
import { IonicStorageModule } from "@ionic/storage-angular";
import { BillingModule } from "@modules/billing/billing.module";
import { ClientSharedModule } from "@modules/clients/client-shared/client-shared.module";
import { AssignmentHelper } from "@modules/clients/modules/assignments/helpers/assignment.helper";
import { DocumentsSharedModule } from "@modules/documents/documents-shared/document-shared.module";
import { PreviewStrategyFactory } from "@modules/documents/documents-shared/services/preview-strategy-factory";
import { ExpatsSharedModule } from "@modules/expats/expats-shared/expats-shared.module";
import { ExpatsService } from "@modules/expats/services/expats.service";
import { OrderSharedModule } from "@modules/order/order-shared/order-shared.module";
import { TimelineService } from "@modules/timeline/services/timeline.service";
import { TimelineModule } from "@modules/timeline/timeline.module";
import { CarouselModule } from "ngx-owl-carousel-o";
import { AsideNavigationService } from "./_services/aside-navigation.service";
import { CrmService } from "./_services/crm.service";
import { CrossNotificationComponent } from "./_services/cross-component-notifications.component";
import { DictionariesService } from "./_services/dictionaries.service";
import { DocumentsService } from "./_services/documents.service";
import { FinancialReportChartService } from "./_services/financial-report-chart.service";
import { GoogleAnalyticsService } from "./_services/google-analytics.service";
import { InvoicesService } from "./_services/invoices.service";
import { LoadingService } from "./_services/loading.service";
import { MenuBadgesService } from "./_services/menu-badges.service";
import { OrdersService } from "./_services/orders.service";
import { ProvidersService } from "./_services/providers.service";
import { QueryParamsService } from "./_services/query-params.service";
import { SalesService } from "./_services/sales.service";
import { ServiceCategoriesService } from "./_services/servicecategories.service";
import { SvgRegistryService } from "./_services/svg-registry.service";
import { UsersService } from "./_services/users.service";
import { WindowResizeHelperService } from "./_services/window-resize-helper.service";
import { ApiModule } from "@api/api.module";
import { AppEffects } from "./app.effects";
import { AssignExpatAssignmentDialogComponent } from "./components/dashboard/orders/assign-expat-assignment-dialog/assign-expat-assignment-dialog.component";
import { AssignExpatDialogComponent } from "./components/dashboard/orders/assign-expat-dialog/assign-expat-dialog.component";
import { OfferExpatItemComponent } from "./components/dashboard/orders/offer-expat-item/offer-expat-item.component";
import { OpenCaseButtonComponent } from "./components/dashboard/orders/open-case-button/open-case-button.component";
import { OrderAssignmentDetailsComponent } from "./components/dashboard/orders/order-assignment-details/order-assignment-details.component";
import { OrderQuestionsComponent } from "./components/dashboard/orders/order-questions/order-questions.component";
import { QuestionsButtonComponent } from "./components/dashboard/orders/questions-button/questions-button.component";
import { WorkInProgressComponent } from "./components/work-in-progress/work-in-progress.component";
import { ReportsModule } from "@modules/reports/reports.module";
import { ServiceOffersService } from "@modules/service-offers/services/service-offers.service";
import { reducers } from "./reducers";
import { StatsModule } from "@api/stats";
import { CompanyMilestonesComponent } from "./components/account-settings/company-milestones/company-milestones.component";
import { CompanyModule } from "@modules/company/company.module";
import { AccountModule } from "@modules/account/account.module";
import {
  RECAPTCHA_SETTINGS,
  RecaptchaFormsModule,
  RecaptchaModule,
  RecaptchaSettings,
} from "ng-recaptcha";
import { OrderHelper } from "@helpers/_models/order.helper";
import { DateModule } from "@modules/util/date/date.module";

import { CategoriesTermsDialogComponent } from "./components/dashboard/categories/categories-terms-dialog/categories-terms-dialog.component";
import { MAT_CHECKBOX_DEFAULT_OPTIONS } from "@angular/material/checkbox";
import { DateHelper } from "@helpers/date.helper";
import { localStorageSync } from "ngrx-store-localstorage";
import { OrderModule } from "@modules/order/order.module";
import { QuillModule } from "ngx-quill";

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

export function localStorageSyncReducer(
  reducer: ActionReducer<any>
): ActionReducer<any> {
  return localStorageSync({ keys: ["tableColumnSettings"], rehydrate: true })(
    reducer
  );
}

const metaReducers: Array<MetaReducer<any, any>> = [localStorageSyncReducer];

@NgModule({
  declarations: [
    AppComponent,

    // ########## COMPONENTS ##########
    LoginComponent,
    RecoverPasswordComponent,
    CreateProfileComponent,
    RegisterComponent,
    ResetPasswordComponent,
    TranslateComponent,
    DashboardLayoutComponent,
    AssignExpatDialogComponent,
    AssignExpatAssignmentDialogComponent,
    OfferExpatItemComponent,
    LeftNavigationComponent,
    LeftNavigationXsComponent,
    HeaderComponent,
    HeaderXsComponent,
    DetailsComponent,
    AddCommissionsComponent,
    ChipsSelectComponent,
    ServicePortfolioComponent,
    OrderComponent,
    OrderDetailsComponent,
    OrderDocumentsComponent,
    OrderInvoicesComponent,
    ClientDetailsModalComponent,
    DashBoardDocuments,
    PortalBreadcrumbComponent,
    TopMenuComponent,
    TermsComponent,
    PrivacyComponent,
    StaticLayoutComponent,
    AddDocComponent,
    AccountSettingsComponent,
    UsersListComponent,
    AccessDeniedPageComponent,
    CategoriesTreeComponent,
    ProviderCategoriesComponent,
    AddCategoriesComponent,
    StatisticsDateFilterComponent,
    AccountComponent,
    AccountDocumentsComponent,

    // ########## LAYOUT ##########
    AuthLayoutComponent,
    DashboardLayoutComponent,

    // ########## DIRECTIVES ##########
    AlertComponent,
    ExpandMenuDirective,
    SsoComponent,
    ToggleRowDirective,
    WindowScrollTableBulkDirective,
    WindowScrollDashboardBodyActionDirective,
    WindowResizeDirective,
    InvoicesListComponent,
    InvoiceCommissionsComponent,
    InvoiceComponent,
    NotFoundComponent,
    CookieConsentComponent,
    AdsComponent,
    CreateAdBannerComponent,
    UpdateAdBannerComponent,
    DetailsCampaignComponent,
    PreviewBannerComponent,
    UserAgreementsComponent,
    ConfirmEmailComponent,
    ConfirmInvoicePaymentComponent,
    PayInvoiceComponent,
    OrderQuotesComponent,
    OrderActivityComponent,
    AccountActivityComponent,
    WorkInProgressComponent,
    OpenCaseButtonComponent,
    OrderAssignmentDetailsComponent,
    OrderQuestionsComponent,
    QuestionsButtonComponent,
    CompanyMilestonesComponent,
    CategoriesTermsDialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    // #### SHARED MODULES #####
    ExpatsSharedModule,
    ClientSharedModule,
    OrderSharedModule,
    CommonModule,
    SharedModule,
    HttpClientModule,
    ApiModule.forRoot(),
    DateModule,
    // #### STORE #####
    EffectsModule.forRoot([AppEffects, WidgetsEffects]),
    StoreModule.forRoot(reducers, { metaReducers }),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
      autoPause: true, // Pauses recording actions and state changes when the extension window is not open
    }),
    // ########## LIBRARY ##########
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    NoopAnimationsModule,
    QuotesModule,
    OrderModule,
    TimelineModule,
    BillingModule,
    ReportsModule,
    CasesSharedModule,
    AuthorizationModule,
    CarouselModule,
    DocumentsSharedModule,
    IonicStorageModule.forRoot({
      name: "xpath-DB",
      driverOrder: [Drivers.IndexedDB, Drivers.LocalStorage],
    }),
    StatsModule,
    AccountModule,
    CompanyModule,
    RecaptchaFormsModule,
    RecaptchaModule,
    NgOptimizedImage,
    QuillModule.forRoot(),
  ],
  providers: [
    QueryParamsService,
    SvgRegistryService,
    AsideNavigationService,
    AuthenticationService,
    ServiceOffersService,
    DictionariesService,
    ProvidersService,
    AssignmentHelper,
    AlertService,
    CookieService,
    AuthGuard,
    ProfileGuard,
    CompareHelper,
    FilesHelper,
    PreviewStrategyFactory,
    // CategoryHelper,
    LoadingService,
    CurrentUserHelper,
    ServiceCategoriesService,
    CrossNotificationComponent,
    UsersService,
    AdsService,
    OrdersService,
    SalesService,
    CrmService,
    ExpatsService,
    DocumentsService,
    BreadcrumbService,
    WindowResizeHelperService,
    InvoicesService,
    MenuBadgesService,
    ActivityService,
    PermissionsService,
    GoogleAnalyticsService,
    CalendarHelper,
    TimelineService,
    FinancialReportChartService,
    PermissionMapperService,
    ResourcePermissionMapperService,
    OrderHelper,
    DateHelper,

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetArrayInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: GetArrayInterceptor,
      multi: true,
    },
    {
      provide: BACKEND_API_HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
    {
      provide: CategoryHelper,
      useClass: CategoryHelper,
      deps: [ServiceCategoriesService, LoadingService, AlertService],
    },
    {
      provide: RfqHelper,
      useClass: RfqHelper,
      deps: [TranslateService],
    },
    {
      provide: MAT_FORM_FIELD_DEFAULT_OPTIONS,
      useValue: {
        appearance: "outline",
        floatLabel: "always",
        subscriptSizing: "fixed",
      },
    },
    {
      provide: MAT_SNACK_BAR_DEFAULT_OPTIONS,
      useValue: {
        verticalPosition: "top",
        horizontalPosition: "end",
      },
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha_site_key,
      } as RecaptchaSettings,
    },
    { provide: LOCALE_ID, useValue: "en-gb" },
    {
      provide: MAT_CHECKBOX_DEFAULT_OPTIONS,
      useValue: { color: "primary" },
    },
  ],
  // entryComponents: [],
  bootstrap: [AppComponent],
  exports: [],
})
export class AppModule {}
