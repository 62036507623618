import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { OrderRoutingModule } from "./order-routing.module";
import { OrderSharedModule } from "./order-shared/order-shared.module";
import { CreateServiceComponent } from "./components/create-service/create-service.component";
import { SharedModule } from "@modules/shared/shared.module";
import { QuotesModule } from "@modules/quotes/quotes.module";

@NgModule({
  declarations: [CreateServiceComponent],
  imports: [
    CommonModule,
    OrderRoutingModule,
    OrderSharedModule,
    SharedModule,
    QuotesModule,
  ],
})
export class OrderModule {}
