import { Injectable } from "@angular/core";
import { map, Observable } from "rxjs";
import { BackendHttpClient } from "@api/http";
import { FilterRequestData } from "../models/filter-request.model";
import { FilterItemData } from "../models/filter-item.model";

@Injectable({
  providedIn: "root",
})
export class FilterService {
  constructor(private http: BackendHttpClient) {}

  public listFilterItems(
    endpoint: string,
    params: FilterRequestData
  ): Observable<FilterItemData[]> {
    return this.http
      .get<any>(endpoint, { params: { ...params } })
      .pipe(map((data) => data.result));
  }
}
