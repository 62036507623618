<div class="border-r">
  <span class="billing-address-label font-bold mb-4">
    {{ "QUOTES.ORDER.BILLING-ADDRESS-TITLE" | translate }}
    <button
      mat-icon-button
      [matTooltip]="'QUOTES.ORDER.BILLING-ADDRESS-INFO' | translate"
      class="info-button"
    >
      <mat-icon svgIcon="info"></mat-icon>
    </button>
  </span>
  <ng-template #loading>
    <app-loader-spinner-general
      [loadingType]="LoadingTypeEnum.BLOCK"
      [repeat]="1"
    >
    </app-loader-spinner-general>
  </ng-template>

  <ng-container *ngIf="addresses$ | async as addresses; else loading">
    <mat-radio-group
      class="block max-h-72 overflow-y-auto"
      [formControl]="checkoutForm.controls.billingAddressId"
    >
      <mat-radio-button
        class="billing-address-item"
        color="primary"
        *ngFor="let address of addresses"
        [value]="address.id"
      >
        <span class="block text-base font-bold mb-2">
          {{ address.name }}
        </span>
        <div class="text-xs">
          <p>
            {{ address.address }}
          </p>
          <p>
            {{ address.country_code }} {{ address.city }}
            {{ address.zip_code }}
          </p>
          <p>
            {{ "LABEL.TAX_NUMBER" | translate }}:
            {{ address.tax_number | notAvailable }}
          </p>
          <p>
            {{ "LABEL.BANK-ACCOUNT-NUMBER" | translate }}:
            {{ address.bank_account_number | notAvailable }}
          </p>
          <span>
            {{ "LABEL.SWIFT-CODE" | translate }}:
            {{ address.swift_code | notAvailable }}</span
          >
        </div>
      </mat-radio-button>
    </mat-radio-group>
  </ng-container>

  <button
    (click)="createBillingAddress()"
    class="btn-outline-none-primary p-0 flex items-center ml-3 mt-2"
  >
    <mat-icon
      svgIcon="plus-circle"
      class="!w-5 !h-5 font-semibold mr-1"
    ></mat-icon>
    {{ "GENERALS.ADD-BILLING-ADDRESS" | translate }}
  </button>
</div>
<div>
  <span class="block font-bold mb-4">
    {{ "QUOTES.ORDER.HR-CONTACT-TITLE" | translate }}
  </span>
  <mat-form-field class="w-full">
    <mat-label>{{ "LABEL.HR-CONTACT" | translate }}</mat-label>

    <mat-select [formControl]="checkoutForm.controls.contactId">
      <mat-select-trigger>
        <ng-container *ngIf="selectedContact$ | async as selectedContact">
          <ng-container
            *ngTemplateOutlet="userRow; context: { $implicit: selectedContact }"
          ></ng-container>
        </ng-container>
      </mat-select-trigger>
      <mat-option>
        <ngx-mat-select-search
          [placeholderLabel]="'LABEL.NAME' | translate"
          [noEntriesFoundLabel]="'GENERALS.NO-ITEMS-DISPLAY' | translate"
          [formControl]="userSearchControl"
        ></ngx-mat-select-search>
      </mat-option>
      <mat-option
        *ngFor="let contact of users$ | async"
        [value]="contact.id"
        (click)="selectionChanged(contact)"
      >
        <ng-container
          *ngTemplateOutlet="userRow; context: { $implicit: contact }"
        ></ng-container>
      </mat-option>
    </mat-select>
  </mat-form-field>
</div>
<ng-template #userRow let-user>
  <div class="flex items-center">
    <img
      class="avatar"
      [src]="user.primary_image_path"
      [appAvatar]="user.name"
    />
    <span>{{ user.name }}</span>
  </div>
</ng-template>
