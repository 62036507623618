<div class="dialog p-0">
  <app-dialog-header>
    {{ "GENERALS.ADD-SERVICE" | translate }}
  </app-dialog-header>

  <div class="dialog-body">
    <app-loader-spinner-general
      *ngIf="isLoadingGeneral"
      [loadingType]="LoadingType.BIG_INFO"
    ></app-loader-spinner-general>
    <form [formGroup]="caseForm">
      <div class="field-grid overflow-x-hidden" *ngIf="!isLoadingGeneral">
        <mat-form-field
          appearance="outline"
          class="w-full"
          [formGroup]="caseForm"
        >
          <mat-label> {{ "GENERALS.CLIENT" | translate }} </mat-label>
          <mat-select
            [placeholder]="'GENERALS.CLIENT' | translate"
            #singleSelect
            [value]="case.client_id"
            [disabled]="true"
          >
            <mat-option [value]="case.client_id">
              {{ case.client_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="w-full"
          [formGroup]="caseForm"
        >
          <mat-label> {{ "GENERALS.EXPAT" | translate }} </mat-label>
          <mat-select
            [placeholder]="'GENERALS.EXPAT' | translate"
            #singleSelect
            [value]="case.expat_id"
            [disabled]="true"
          >
            <mat-option [value]="case.expat_id">
              {{ case.expat_name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          *ngIf="case.type !== CaseType.ADMIN"
          appearance="outline"
          class="w-full"
          [formGroup]="caseForm"
        >
          <mat-label> {{ "LABEL.ASSIGNMENT" | translate }} </mat-label>
          <mat-select
            [placeholder]="'LABEL.ASSIGNMENT' | translate"
            #singleSelect
            [value]="case.assignment.id"
            [disabled]="true"
          >
            <mat-option [value]="case.assignment.id">
              {{ case.assignment.home_country_data?.name }} to
              {{ case.assignment.host_country_data?.name }}
              ( {{ case.assignment.start_date | date : "dd/MM/yyyy" }} -
              {{ case.assignment.end_date | date : "dd/MM/yyyy" }} )
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field
          appearance="outline"
          class="w-full"
          [formGroup]="caseForm"
          *ngIf="order$ | async as order"
        >
          <mat-label> {{ "LABEL.ORDER" | translate }} </mat-label>
          <mat-select
            [placeholder]="'LABEL.ORDER' | translate"
            #singleSelect
            [value]="order.identity_number"
            [disabled]="true"
          >
            <mat-option [value]="order.identity_number">
              {{ order.identity_number }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <app-order-services-selector
          class="w-full"
          *ngIf="order$ | async as order"
          [formGroup]="caseForm"
          [order]="order"
          [submitEvent]="submitForm.asObservable()"
        ></app-order-services-selector>
        <app-services-selector
          class="w-full"
          *ngIf="case.type === CaseType.ADMIN"
          [formGroup]="caseForm"
          [submitEvent]="submitForm.asObservable()"
        ></app-services-selector>
      </div>
    </form>
  </div>

  <div class="dialog-footer">
    <button
      class="btn-primary"
      (click)="submit()"
      [disabled]="isLoadingGeneral"
    >
      <mat-icon svgIcon="save" class="btn-icon"></mat-icon>
      {{ "GENERALS.SAVE" | translate }}
    </button>
  </div>
</div>
